<template>
  <div class="layout-center-block">
    <div class="lcb-container1">
      
      <div class="start-form-wrapper">
        <!--<img src="@img/cover-start3.png" alt="cover">-->
        <div class="lcb-container2 start-form">
  
  
  
          <div>
            <h4 class="auth__title">Zombty</h4>
            <h1 class="auth__title">Meet <span>Global</span></h1>
            <h1 class="auth__title">Community</h1>
            <p class="auth__main">
              One web application for business and 
              entertainment
            </p>
  
            <router-link :to="{ name: $routeName.USER_AUTH }" class="auth__btn">Sign Up</router-link>
          </div>
          
          
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import UserTemplate from "@user/page/_component/UserTemplate";

export default {
  name: 'Start',
  components: {
  },
  data() {
  },
  methods: {
  }
};


</script>

<style lang="scss" scoped>

h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 145%;
  color: #566BD6;
  
  margin: 0;
  padding-bottom: 20px;
}
h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
  line-height: 92%;
  display: flex;
  align-items: center;
  color: #424250;
  
  > span {
    color: #566BD6;
  }
  
  margin: 0;
}

p {
  padding-top: 20px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 145%;
  color: #424250;
}

.auth__btn {
  margin-top: 40px;
  max-width: 315px;
  
  display: inline-block;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  background-color: var(--color-primary);
  color: #fff;
  padding: 12px 24px;
  border: 0;
  cursor: pointer;
  line-height: 24px;
  font-size: 16px;
  transition: 0.25s ease;
  &:hover {
    background-color: #F98D5F;
  }
}




.layout-center-block {
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
  padding: 15px;
  
  background-image: url("~@img/cover-start3.png");
  background-repeat: no-repeat;
}

.start-form-wrapper {
  //width: 1050px;
  //margin: auto;
  width: 100%;
  position: relative;
  max-height: 720px;
  height: 100%;
}

.start-form {
  position: absolute;
  top: 50%;
  left: 80px;
  transform: translateY(-50%);
}

.lcb-container1 {
  width: 100%;
  height: 100%;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lcb-container2 {
  width: 100%;
  max-width: 700px;
  //background: var(--color-gray-011);
  //box-shadow: 0px 12px 16px rgba(6, 18, 55, 0.06), 6px 20px 36px rgba(6, 18, 55, 0.02);
  //border-radius: 8px;
  padding: 52px;
  margin: auto;
}

@media screen and (max-width: 656px) {
  .layout-center-block {
    padding: 15px 70px;
  }
  .lcb-container2 {
    padding: 57px 25px 50px;
  }
}

@media screen and (max-width: 500px) {
  .layout-center-block {
    padding: 15px 15px;
  }
  .lcb-container2 {
    padding: 57px 25px 50px;
  }
}
</style>
